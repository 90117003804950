import {createGlobalStyle} from 'styled-components'

import {tokens} from '@pleo-io/telescope'

import {fontFamilyCode} from '@/tokens'

import {StyledEditor} from '../code/code'
import {Table} from '../docs/table'

export const minWidthTOC = '1200px'

export const GlobalDocsStyles = createGlobalStyle`
    body {
        background-color: ${tokens.colorBackgroundStaticLoudest};
        color: ${tokens.colorContentStatic};
    }


    /* Temporary workaround to not have underlines for the tab triggers
    in our Tabs component documentation. This global link styling should
    be killed with fire! */

    a[role="tab"] {
        text-decoration: none;
    }

    i {
        font-weight: ${tokens.fontWeightMedium};
    }

    code {
        margin: 0;
        padding: .25em .4em; /* stylelint-disable-line */
        /* stylelint-disable-next-line declaration-property-value-allowed-list */
        font-size: 85%;
        font-family: ${fontFamilyCode};
        background-color: ${tokens.colorBackgroundStaticLoudest};
        border-radius: ${tokens.arc4};
        color: ${tokens.colorContentStatic};
        white-space: nowrap;

        a & {
            color: ${tokens.colorContentInteractiveLink};
        }

        a:hover & {
            color: ${tokens.colorContentInteractiveLinkHover};
        }
    }

    pre[class*='language-'],
    code[class*='language-'] {
        color: ${tokens.colorContentStaticInverse};
        border: ${tokens.borderStatic};
        font-size: 13px; /* stylelint-disable-line */
        font-family: ${fontFamilyCode};
        line-height: ${tokens.lineHeight2};
        direction: ltr;
        white-space: pre;
        text-align: left;
        text-shadow: none;
        word-break: normal;
        word-spacing: normal;
        tab-size: 4;
        hyphens: none;
        -webkit-font-smoothing: auto;
    }

    pre[class*='language-'] {
        width: 100%;
        padding: ${tokens.spacing18};
        overflow: auto;
        background-color: ${tokens.colorBackgroundStaticInverse};
        border-radius: ${tokens.arc8};
    }

    /* stylelint-disable no-duplicate-selectors */
    ${StyledEditor} + h1,
    ${StyledEditor} + h2,
    ${StyledEditor} + h3,
    ${StyledEditor} + h4,
    ${StyledEditor} + h5,
    ${StyledEditor} + h6,
    ${Table} + h1,
    ${Table} + h2,
    ${Table} + h3,
    ${Table} + h4,
    ${Table} + h5,
    ${Table} + h6,
    ${StyledEditor} + h1,
    ${StyledEditor} + h2,
    ${StyledEditor} + h3,
    ${StyledEditor} + h4,
    ${StyledEditor} + h5,
    ${StyledEditor} + h6,
    pre + h1,
    pre + h2,
    pre + h3,
    pre + h4,
    pre + h5,
    pre + h6 {
        margin-top: ${tokens.spacing36}
    }
    /* stylelint-enable no-duplicate-selectors */
    hr {
        border: none;
        height: 1px;
        background-color: ${tokens.colorBorderStatic};
        margin: ${tokens.spacing48} 0;
    }
`
