import type {FC} from 'react'
import React from 'react'
import ReactMarkdown from 'react-markdown'
import styled from 'styled-components'

import {Box, Inline, NakedButton, Popover, Text, tokens} from '@pleo-io/telescope'
import {Minus, Info} from '@pleo-io/telescope-icons'

import {
    Table,
    TableHead,
    TableHeadRow,
    TableHeadData,
    TableBody,
    TableBodyData,
    TableBodyRow
} from '@/components/docs/table'
import {fontFamilyCode} from '@/tokens'
import {sort} from '@/utilities/sort'

import telescopeDocs from '../../../dist/docgen.json'

const InfoButton = styled(NakedButton)`
    padding: 0.25em; /* stylelint-disable-line */
    margin-left: ${tokens.spacing6};
    display: inline-flex;
    border-radius: ${tokens.arc4};

    &:hover {
        background-color: ${tokens.colorBackgroundInteractiveHover};
    }
`

const IGNORED_PROP_NAMES = ['data-telescope']

export interface DocgenProp {
    defaultValue: any
    description: string
    name: string
    parent?: {
        fileName: string
        name: string
    }
    required: boolean
    type: {
        name: string
        raw?: string | null
        value?: Array<{value: string}> | null
    }
}

interface ComponentDocs {
    description: string
    displayName: string
    methods: Array<any>
    props: {
        [prop: string]: DocgenProp
    }
}

export const InfoPopover = ({
    children,
    Icon
}: {
    children: React.ReactNode
    Icon?: React.ComponentType
}) => {
    const IconComponent = Icon || Info
    return (
        <Popover>
            <Popover.Trigger>
                <InfoButton aria-label="Show prop description">
                    <IconComponent size={16} color={tokens.colorContentInteractiveQuiet} />
                </InfoButton>
            </Popover.Trigger>
            <Popover.Content side="top">
                <Box p={12} style={{maxWidth: 350}}>
                    <Text>{children}</Text>
                </Box>
                <Popover.Arrow />
            </Popover.Content>
        </Popover>
    )
}

const showPropTypeDetails = (prop: DocgenProp) => {
    const {name, raw} = prop.type

    if (
        raw === 'boolean' ||
        name === 'string' ||
        name === 'any' ||
        name === 'number' ||
        name === 'never'
    ) {
        return false
    }

    return true
}

const getDisplayName = (prop: DocgenProp) => {
    const {name, raw} = prop.type

    if (name.startsWith('Pick')) {
        return 'object'
    } else if (name[0] === '(') {
        return 'function'
    } else if (raw === 'boolean') {
        return 'boolean'
    }
    return showPropTypeDetails(prop) ? name : name.toLowerCase()
}

const PropTypeValues = ({prop}: {prop: DocgenProp}) => {
    if (!prop.type.value) {
        return <>{prop.type.name}</>
    }

    return (
        <>
            {prop.type.value.map((v, i) => {
                const isLastItem = i === (prop.type.value?.length || 1) - 1
                return (
                    <React.Fragment key={v.value}>
                        {v.value}
                        {isLastItem ? '' : ', '}
                    </React.Fragment>
                )
            })}
        </>
    )
}

const CodeSubtle = styled.span`
    margin: 0;
    padding: 0.25em 0.4em; /* stylelint-disable-line */
    /* stylelint-disable-next-line declaration-property-value-allowed-list */
    font-size: 85%;
    font-family: ${fontFamilyCode};
    background-color: ${tokens.colorBackgroundStaticLoud};
    border-radius: ${tokens.arc4};
    color: ${tokens.colorContentStatic};
`

const NoPropsText = styled(Text)`
    color: ${tokens.colorContentStaticQuiet};
    background-color: ${tokens.colorBackgroundStaticLoud};
    padding: ${tokens.spacing20};
    border-radius: ${tokens.arc8};
    text-align: center;
    margin: ${tokens.spacing20} 0;
`

const PropNameCode = styled.code<{experimental?: boolean}>`
    background: ${({experimental}) =>
        experimental ? tokens.colorBackgroundWarning : tokens.colorBackgroundPresentationalPink};
`

type PropNameType = {name: string; description: string; required: boolean; experimental?: boolean}
const PropName = ({name, description, required, experimental}: PropNameType) => {
    return (
        <Inline alignY="center">
            <PropNameCode experimental={experimental}>{name}</PropNameCode>
            {required ? '*' : ''}
            <InfoPopover>{description || 'No description available'}</InfoPopover>
        </Inline>
    )
}

const DefaultValue = ({defaultValue}: {defaultValue?: string}) => {
    if (!defaultValue) {
        return <Minus color={tokens.colorContentStaticQuiet} size={14} />
    }

    return <CodeSubtle>{defaultValue}</CodeSubtle>
}

export const Props: FC<{
    ignore?: string[]
    of: keyof typeof telescopeDocs
}> = ({of: component, ignore}) => {
    const componentDocs: ComponentDocs = telescopeDocs[component]

    if (!componentDocs) {
        return <NoPropsText>This component doesn't accept any custom props.</NoPropsText>
    }

    const ignoredPropNames = IGNORED_PROP_NAMES.concat(ignore || [])
    const sortedAndFiltered = Object.values(componentDocs?.props || {})
        .sort(sort((prop) => prop.name.toLowerCase()))
        .filter((prop) => !ignoredPropNames?.includes(prop.name))

    if (sortedAndFiltered.length === 0) {
        return <NoPropsText>This component doesn't accept any custom props.</NoPropsText>
    }

    const rows = sortedAndFiltered.map((prop) => (
        <TableBodyRow key={prop.name}>
            <TableBodyData>
                <PropName {...prop} />
            </TableBodyData>
            <TableBodyData>
                <Inline alignY="center">
                    <CodeSubtle>{getDisplayName(prop)}</CodeSubtle>
                    {showPropTypeDetails(prop) && (
                        <InfoPopover>{<PropTypeValues prop={prop} />}</InfoPopover>
                    )}
                </Inline>
            </TableBodyData>
            <TableBodyData>
                <DefaultValue defaultValue={prop?.defaultValue?.value} />
            </TableBodyData>
        </TableBodyRow>
    ))

    return (
        <>
            <Text variant="medium-default" as="p" space={20}>
                <ReactMarkdown>{componentDocs.description}</ReactMarkdown>
            </Text>
            {rows.length > 0 && (
                <Table $fluid>
                    <TableHead>
                        <TableHeadRow>
                            <TableHeadData>Prop</TableHeadData>
                            <TableHeadData>Type</TableHeadData>
                            <TableHeadData>Default</TableHeadData>
                        </TableHeadRow>
                    </TableHead>
                    <TableBody>{rows}</TableBody>
                </Table>
            )}
        </>
    )
}

export interface RawTableProps {
    entries: Array<TableEntryProps>
}

export interface TableEntryProps {
    name: string
    required: boolean
    defaultValue: string
    type: string
    description: any
}

export const RawPropsTable = ({entries}: RawTableProps) => {
    return (
        <Table $fluid>
            <TableHead>
                <TableHeadRow>
                    <TableHeadData>Prop</TableHeadData>
                    <TableHeadData>Type</TableHeadData>
                    <TableHeadData>Default</TableHeadData>
                </TableHeadRow>
            </TableHead>
            <TableBody>
                {entries.map((entry) => (
                    <TableBodyRow key={entry.name}>
                        <TableBodyData>
                            <PropName {...entry} />
                        </TableBodyData>
                        <TableBodyData>
                            <ReactMarkdown>{entry.type}</ReactMarkdown>
                        </TableBodyData>
                        <TableBodyData>
                            <DefaultValue defaultValue={entry.defaultValue} />
                        </TableBodyData>
                    </TableBodyRow>
                ))}
            </TableBody>
        </Table>
    )
}
